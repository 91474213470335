// src/components/admin/componentes/FormLogin.js
import React, { useContext, useState } from 'react';
import { Button, Container, Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Eye, EyeSlash, QuestionCircle } from 'react-bootstrap-icons';
import { Checkbox, IconButton, Input, InputLabel, InputAdornment, FormControl, FormControlLabel } from '@mui/material';
import { AuthContext } from '../../../contexts/AuthContext';
import Swal from 'sweetalert2';

function FormLogin() {
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);  // Añadimos el estado para 'Recuerdame'
    const renderTooltip = (props) => (
        <Tooltip id='button-tooltip' {...props}>
            Te recordaremos por 15 dias
        </Tooltip>
    );
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const email = event.target.EmailUser.value;
        const password = event.target.PasswordUser.value;

        try {
            await login(email, password, rememberMe);  // Pasamos 'rememberMe' al login
            navigate('/admin/Dashboard/');
        } catch (error) {
            console.error('Error during login:', error.response ? error.response.data : error.message);
            Swal.fire({
                title: 'Error',
                text: "El usuario que ingresó no es válido o no está registrado",
                icon: 'error',
                confirmButtonText: 'Cerrar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            })
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');  // Redirige a la vista de recuperación de contraseña
    };

    return (
        <Container>

            <Card className='rounded-4 border-0 mx-auto my-4' style={{ maxWidth: '500px' }}>
                <Card.Body className='mx-4 mt-3'>
                    <div className='mb-2 text-center'>
                        <h3 className='fw-bold text-dark'>Iniciar sesión</h3>
                        <p className='text-muted mt-4'>¡Introduce tu email y contraseña para iniciar sesión!</p>
                    </div>

                    <Row className='my-2'>
                        <Col><hr /></Col> o <Col><hr /></Col>
                    </Row>
                    <form onSubmit={handleSubmit}>
                        <FormControl className='w-100' variant='standard'>
                            <InputLabel>Correo Electronico</InputLabel>
                            <Input
                                id='EmailUser'
                                type='email'
                                className='mb-2'
                                required
                            />
                        </FormControl>
                        <FormControl className='w-100' variant='standard'>
                            <InputLabel>Contraseña</InputLabel>
                            <Input
                                id='PasswordUser'
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <EyeSlash /> : <Eye />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                            />
                        </FormControl>
                        <Row className='mb-4 mt-2'>
                            <Col xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={rememberMe}
                                            onChange={(e) => setRememberMe(e.target.checked)}  // Actualiza el estado
                                        />
                                    }
                                    label="Recuérdame"
                                />
                                <OverlayTrigger
                                    placement='right'
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <QuestionCircle className='me-2' />
                                </OverlayTrigger>
                            </Col>
                            <Col xs={6} className='text-end'>
                                <Button variant='link' className='text-decoration-none' onClick={handleForgotPassword}>
                                    Olvidé la contraseña?
                                </Button>
                            </Col>
                        </Row>
                        <Button type="submit" variant='primary' className='w-100'>Iniciar sesión</Button>
                    </form>
                    <div className='text-center mt-3'>
                        <small className='text-muted'>
                            ¿Aún no estás registrado?
                        </small>
                        <Button
                            variant='link'
                            className='text-decoration-none'
                            onClick={() => {
                                const whatsappUrl = `https://wa.me/+573106014402` +
                                    `?text=${encodeURIComponent('Estoy interesado en crear una cuenta en app TuBisnez')}`;
                                window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
                            }}
                        >
                            Crea una cuenta
                        </Button>

                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default FormLogin;
