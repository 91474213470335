import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ChevronDoubleLeft } from 'react-bootstrap-icons';
import FormLogin from './componentes/FormLogin';
import DecoLogin from './componentes/DecoLogin';
import '../../utils/login.css';

function AdminLogin() {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <section className="background-radial-gradient overflow-hidden">
            <div className="px-md-5 text-center text-lg-start my-5">
                <Link to='../'>
                    <Button variant="link" className="text-light text-decoration-none">
                        <ChevronDoubleLeft className='text-light me-2' /> Volver a comercio
                    </Button>
                </Link>
                <div className="row gx-lg-5 align-items-center">
                    <DecoLogin />
                    <div id="radius-shape-3" className="position-absolute rounded-circle shadow-5-strong"></div>
                    <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
                        <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
                        <FormLogin />
                    </div>
                </div>
            </div>
            <footer className="mx-4 mb-2 text-light fixed-bottom" style={{ zIndex: '10' }}>
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="text-center text-md-start mb-2 mb-md-0">
                        <div className="mb-0">
                            Desarrollado por
                            <small className='text-decoration-none fw-bold'><a href="https://tubisnez.com" target="_blank" rel="noopener noreferrer" className='text-decoration-none text-dark'> TuBisnez</a> &copy; 2023 - {getCurrentYear()}</small>
                        </div>
                    </Col>
                </Row>
            </footer>
        </section>
    );
}

export default AdminLogin;
