import React, { useState, useEffect } from 'react';
import { Nav, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Whatsapp, EnvelopeAtFill, PinMapFill } from 'react-bootstrap-icons';
import { useTiendaConfig } from '../../hooks/useTiendaConfig';
import { API } from '../../api/URL_API';
import axios from 'axios';
import { useCompanyInfo } from '../../hooks/useCompanyInfo';
import getIcon from '../../utils/getIcon';
import { useSucursal } from '../../contexts/SucursalContext';
import { useParams } from 'react-router-dom';

const getCurrentYear = () => {
  return new Date().getFullYear();
};

function FooterPublic() {
  const { idSucursal: contextIdSucursal } = useSucursal(); // Obtener idSucursal del contexto
  const { idSucursal: paramIdSucursal } = useParams(); // Obtener idSucursal de los parámetros de la URL
  const idSucursal = paramIdSucursal || contextIdSucursal; // Usar el idSucursal de la URL si está disponible, de lo contrario, usar el del contexto

  const { socialLinks } = useTiendaConfig();
  const { companyData } = useCompanyInfo();

  // URLs utilizando el idSucursal dinámico
  const urlAll = `/Collections/${idSucursal}/Products/All`;
  const urlMoreSale = `/Collections/${idSucursal}/Products/MoreSales`;
  const urlNewProduct = `/Collections/${idSucursal}/Products/NewProductos`;
  const urlCollections = `/Collections/${idSucursal}`;

  const [formData, setFormData] = useState({ nameuser: '', email: '' });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${API}/api/marketing/subscribe`, formData);
      setMessage({ text: response.data.message, type: 'success' });
      setFormData({ nameuser: '', email: '' });
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Hubo un error al suscribirse. Inténtalo de nuevo.';
      setMessage({ text: errorMsg, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  }, [idSucursal]);


  return (
    <>
      {/* SVG de transición en forma de onda invertida */}
      <svg viewBox="0 -1 1440 300" className="wave-transition wave-transition-top" xmlns="http://www.w3.org/2000/svg">
        <path fill="#2b235e" fillOpacity="1" d="M0,96L40,90.7C80,85,160,75,240,106.7C320,139,400,213,480,213.3C560,213,640,139,720,128C800,117,880,171,960,202.7C1040,235,1120,245,1200,240C1280,235,1360,213,1400,202.7L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
      </svg>

      <footer className="text-light text-center bg-cr">
        <Row className="align-items-start mx-4">
          <Col xs={12} md={3} className="mb-4">
            <h5 className='text-light fst-italic fw-bold'>SUSCRIBETE</h5>
            <p className='text-light'>Suscribete y obten increibles descuentos en tus compras.</p>
            {message && (
              <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                {message.text}
              </div>
            )}

            <Form onSubmit={handleSubmit}>
              <InputGroup className="justify-content-center mb-3">
                <Form.Control
                  type="text"
                  name="nameuser"
                  placeholder="Ingresa tu nombre"
                  aria-label="Ingresa tu nombre"
                  value={formData.nameuser}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Ingresa tu email aquí"
                  aria-label="Ingresa tu email aquí"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </InputGroup>
              <button type="submit" className="button-suscribe mt-3 w-100 text-center" disabled={loading}>
                {loading ? 'Cargando...' : 'Suscríbete'}
              </button>
            </Form>
          </Col>
          <Col xs={12} md={3} className="mb-4">
            <h5 className="fw-bold text-center fst-italic">SIGUENOS EN</h5>
            <div className="d-flex justify-content-center">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target='_blank'
                  rel="noopener noreferrer"
                  title={link.title}
                >
                  <button className="button-social">
                    {getIcon(link.url)}
                  </button>
                </a>
              ))}
            </div>
          </Col>
          {/* SHOP Section */}
          <Col xs={12} md={3} className="mb-4">
            <h5 className="fw-bold text-center fst-italic">SHOP</h5>
            <Nav className="flex-column align-items-center">
              <Nav.Link href={urlAll} className='text-light'>Todos los productos</Nav.Link>
              <Nav.Link href={urlNewProduct} className='text-light'>Lo mas nuevo ⚡</Nav.Link>
              <Nav.Link href={urlMoreSale} className='text-light'>Lo mas vendido 🔥</Nav.Link>
              <Nav.Link href={urlCollections} className='text-light'>Colecciones ✨</Nav.Link>
            </Nav>
          </Col>
          <Col xs={12} md={3} className="mb-4">
            <h5 className="fw-bold fst-italic">CONTACTANOS</h5>
            <div className="flex-column">
              <p className='text-light'><Whatsapp className='text2-cr me-2' size={18} />+57 {companyData.telefono}</p>
              <p className='text-light'><EnvelopeAtFill className='text2-cr me-2' size={18} />{companyData.email}</p>
              <p className='text-light'><PinMapFill className='text2-cr me-2' size={18} />{companyData.direccion} {companyData.ciudad} - {companyData.departamento}</p>
            </div>
          </Col>
        </Row>
        <div className="mb-0 text-center">
          &copy; {getCurrentYear()}
          <small className="text-decoration-none text2-cr fw-bold"> CrishaCaps</small>.
          Desarrollado en Colombia por 
          <a
            href="https://www.tubisnez.com"
            target="_blank"
            rel="noopener noreferrer"
            className="ms-1 text-decoration-none text2-cr fw-bold"
          >
             TuBisnez
          </a>. 💛💙❤️
        </div>
      </footer>
    </>
  );
}

export default FooterPublic;
