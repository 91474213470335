import React from 'react';
import { Button, Container, ListGroup, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap';
import { TrashFill, PencilFill } from 'react-bootstrap-icons';
import { useTiendaConfig } from '../../../../hooks/useTiendaConfig';
import './TiendaConfig.css';
import EnviosTienda from './tienda/enviosConfig';
import DescuentosTienda from './tienda/descuentosConfig';
import getIcon from '../../../../utils/getIcon';

function TiendaConfig() {
    const {
        socialLinks,
        newLink,
        editIndex,
        setNewLink,
        addLink,
        editLink,
        saveLink,
        cancelEdit,
        deleteLink
    } = useTiendaConfig();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewLink({ ...newLink, [name]: value });
    };

    return (
        <div className='m-4'>
            <Container>
                {/* Se elimino solo front de la imagen banner y perfil */}
                <div className='mt-4'>
                    <h2 className='fw-bold text-black'>Enlaces de redes sociales</h2>
                    <p>Añade hasta 4 enlaces a redes sociales que se mostrarán en el perfil de tu tienda.</p>
                    {(socialLinks.length < 4 && editIndex === -1) && (
                        <InputGroup className='mb-3'>
                            <FormControl
                                placeholder='Título del enlace'
                                name='title'
                                value={newLink.title}
                                onChange={handleChange}
                            />
                            <FormControl
                                placeholder='URL del enlace'
                                name='url'
                                value={newLink.url}
                                onChange={handleChange}
                            />
                            <Button variant='primary' onClick={addLink}>
                                Añadir
                            </Button>
                        </InputGroup>
                    )}
                    <ListGroup>
                        {socialLinks.map((link, index) => (
                            <ListGroup.Item key={index} className='d-flex justify-content-between align-items-center'>
                                {editIndex === index ? (
                                    <>
                                        <InputGroup className='mb-3'>
                                            <FormControl
                                                placeholder='Título del enlace'
                                                name='title'
                                                value={newLink.title}
                                                onChange={handleChange}
                                            />
                                            <FormControl
                                                placeholder='URL del enlace'
                                                name='url'
                                                value={newLink.url}
                                                onChange={handleChange}
                                            />
                                            <Button variant='primary' onClick={saveLink}>
                                                Guardar
                                            </Button>
                                            <Button variant='secondary' onClick={cancelEdit}>
                                                Cancelar
                                            </Button>
                                        </InputGroup>
                                    </>
                                ) : (
                                    <div className='d-flex justify-content-between w-100'>
                                        <div>
                                            {getIcon(link.url)}
                                            <strong className='ms-2'>{link.title}</strong> <br />
                                            <a href={link.url} target='_blank' rel='noopener noreferrer'>
                                                {link.url}
                                            </a>
                                        </div>
                                        <ButtonGroup>
                                            <Button variant='link' onClick={() => editLink(index)} className='text-warning'>
                                                <PencilFill size={18} />
                                            </Button>
                                            <Button variant='link' onClick={() => deleteLink(link.id)} className='text-danger'>
                                                <TrashFill size={18} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                )}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>

                <EnviosTienda />
                <DescuentosTienda />
            </Container>

        </div>
    );
}

export default TiendaConfig;
