import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Table, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { API } from '../../../../../api/URL_API';

function DescuentosTienda() {
    const [reglasDescuento, setReglasDescuento] = useState([]);
    const [nuevaRegla, setNuevaRegla] = useState({
        tipoRegla: 'cantidad',
        min_cantidad: '',
        max_cantidad: '',
        min_total: '',
        max_total: '',
        porcentajeDescuento: ''
    });
    const [editMode, setEditMode] = useState(false);
    const [idEdit, setIdEdit] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [tipoConfig, setTipoConfig] = useState(); // Valor por defecto


    useEffect(() => {
        fetchReglasDescuento(tipoConfig);  // Llamar a la API para obtener las reglas filtradas por tipoConfig
        fetchTipoConfig();
    }, [tipoConfig]);

    useEffect(() => {
        if (editMode) {
            setNuevaRegla(prevState => ({
                ...prevState,
                tipoRegla: tipoConfig // Asegura que tipoRegla esté sincronizado con tipoConfig
            }));
        }
    }, [tipoConfig, editMode]);

    const fetchReglasDescuento = async (tipoRegla = null) => {
        try {
            let url = `${API}/api/descuentos`;
            if (tipoRegla) {
                url += `?tipoRegla=${tipoRegla}`; // Agregar el filtro tipoRegla como query parameter
            }

            const response = await axios.get(url);
            setReglasDescuento(response.data);
        } catch (error) {
            console.error("Error fetching reglas de envío", error);
        }
    };

    const fetchTipoConfig = async () => {
        try {
            const response = await axios.get(`${API}/api/descuentos/configDescuento/1`);
            setTipoConfig(response.data.tipoConfig);  // Actualizar el estado del tipo de configuración
        } catch (error) {
            console.error("Error fetching tipoConfig", error);
        }
    };

    const handleSelectChanges = async (selectedOption) => {
        // Preguntar al usuario si está seguro antes de actualizar
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Deseas actualizar la configuración de descuento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, actualizar',
            cancelButtonText: 'Cancelar',
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Actualizar el estado del select localmente
                setTipoConfig(selectedOption.value);

                fetchReglasDescuento(selectedOption.value);
                try {
                    await axios.put(`${API}/api/descuentos/configDescuento/1`, {
                        tipoConfig: selectedOption.value
                    });

                    Swal.fire({
                        title: 'Actualizado',
                        text: 'La configuración de descuento ha sido actualizada correctamente.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#2fb380',
                        confirmButtonColor: "#2c4cc4"
                    })
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al actualizar la configuración de descuento.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e"
                    });
                    console.error("Error updating tipoConfig", error);
                }
            }
        });
    };

    const handleChange = (e) => {
        setNuevaRegla({ ...nuevaRegla, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption) => {
        setNuevaRegla({
            ...nuevaRegla,
            tipoRegla: selectedOption.value // Asegurar que tipoRegla se actualice al cambiar el select
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editMode) {
                await axios.put(`${API}/api/descuentos/${idEdit}`, nuevaRegla);
                Swal.fire({
                    title: 'Actualizado',
                    text: 'La regla de descuento ha sido actualizada correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            } else {
                await axios.post(`${API}/api/descuentos`, nuevaRegla);
                Swal.fire({
                    title: 'Creado',
                    text: 'La nueva regla de descuento ha sido creada correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            }
            fetchReglasDescuento(tipoConfig);
            setShowModal(false);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al guardar la regla de descuento.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
            console.error("Error saving regla de descuento", error);
        }
    };

    const resetForm = () => {
        setNuevaRegla({
            tipoRegla: tipoConfig || 'cantidad', // Asegura que se mantenga el valor del tipo de regla basado en tipoConfig
            min_cantidad: '',
            max_cantidad: '',
            min_total: '',
            max_total: '',
            porcentajeDescuento: ''
        });
        setEditMode(false);
        setIdEdit(null);
    };

    const openModal = () => {
        // Establecer tipoRegla basado en tipoConfig al abrir el modal para una nueva regla
        setNuevaRegla({
            tipoRegla: tipoConfig || 'cantidad',  // Asegura que el valor por defecto sea 'cantidad' si tipoConfig no está disponible
            min_cantidad: '',
            max_cantidad: '',
            min_total: '',
            max_total: '',
            porcentajeDescuento: ''
        });
        setEditMode(false);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        resetForm();
    };

    const handleEdit = (regla) => {
        setEditMode(true);
        setIdEdit(regla.idDescuento);
        setNuevaRegla({
            tipoRegla: regla.tipoRegla,
            min_cantidad: regla.min_cantidad,
            max_cantidad: regla.max_cantidad,
            min_total: regla.min_total,
            max_total: regla.max_total,
            porcentajeDescuento: regla.porcentajeDescuento
        });
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${API}/api/descuentos/${id}`);
                    Swal.fire({
                        title: 'Eliminado',
                        text: 'La regla de descuento ha sido eliminada correctamente.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#2fb380',
                        confirmButtonColor: "#2c4cc4"
                    });
                    fetchReglasDescuento(tipoConfig);
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al eliminar la regla de descuento.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e"
                    });
                    console.error("Error deleting regla de descuento", error);
                }
            }
        });
    };

    const tipoReglaOptions = [
        { value: 'cantidad', label: 'Por Cantidad' },
        { value: 'total', label: 'Por Total' },
        { value: 'desable', label: 'Desactivado' }
    ];

    return (
        <Container>
            <div className='mt-4 d-flex flex-column flex-md-row justify-content-between'>
                <div>
                    <h2 className='fw-bold text-black'>Configuración de Descuentos</h2>
                    <p>Establece rangos para manejar los descuentos aplicables en los pedidos que tus clientes realizan en la tienda.</p>
                </div>

                <div className='mt-2 mt-md-4'>
                    <Button variant="primary" onClick={openModal}>
                        {editMode ? 'Editar Regla de Descuento' : 'Crear Regla de Descuento'}
                    </Button>
                </div>
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editMode ? 'Editar' : 'Crear'} Regla de Descuento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='m-3 mt-0'>
                            <Form.Label>Tipo de Regla</Form.Label>
                            <Select
                                value={tipoReglaOptions.find(option => option.value === nuevaRegla.tipoRegla)}
                                onChange={handleSelectChange}
                                options={tipoReglaOptions}
                                isDisabled // Hacer que el select sea solo de lectura si en modo edición
                                required
                            />
                        </Form.Group>

                        {nuevaRegla.tipoRegla === 'cantidad' && (
                            <>
                                <Form.Group className='m-3'>
                                    <Form.Label>Cantidad mínima</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="min_cantidad"
                                        value={nuevaRegla.min_cantidad}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className='m-3'>
                                    <Form.Label>Cantidad máxima</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="max_cantidad"
                                        value={nuevaRegla.max_cantidad}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </>
                        )}

                        {nuevaRegla.tipoRegla === 'total' && (
                            <>
                                <Form.Group className='m-3'>
                                    <Form.Label>Total mínimo del pedido</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        name="min_total"
                                        value={nuevaRegla.min_total}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Total máximo del pedido</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        name="max_total"
                                        value={nuevaRegla.max_total}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </>
                        )}

                        <Form.Group className='m-3'>
                            <Form.Label>Porcentaje de Descuento</Form.Label>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="porcentajeDescuento"
                                value={nuevaRegla.porcentajeDescuento}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                {editMode ? 'Actualizar' : 'Crear'} Regla
                            </Button>
                            <Button variant="secondary" onClick={closeModal} className="ms-2">
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            <Row>
                <Col>
                    <div className='m-2 d-flex flex-column flex-md-row justify-content-between'>
                        <h4 className='fw-bold'>Reglas de Descuento Existentes</h4>
                        <Select
                            value={tipoReglaOptions.find(option => option.value === tipoConfig)}  // Mostrar el valor actual
                            onChange={handleSelectChanges}  // Llamar al manejador cuando cambie el valor
                            options={tipoReglaOptions}
                            required
                        />
                    </div>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Cantidad/Total Mínimo</th>
                                <th>Cantidad/Total Máximo</th>
                                <th>Porcentaje Descuento</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(reglasDescuento) && reglasDescuento.length > 0 ? (
                                reglasDescuento.map((regla) => (
                                    <tr key={regla.idDescuento}>
                                        <td>{regla.tipoRegla === 'cantidad' ? 'Por Cantidad' : 'Por Total'}</td>
                                        <td>{regla.tipoRegla === 'cantidad' ? regla.min_cantidad : regla.min_total}</td>
                                        <td>{regla.tipoRegla === 'cantidad' ? (regla.max_cantidad || 'Sin límite') : (regla.max_total || 'Sin límite')}</td>
                                        <td>{regla.porcentajeDescuento}%</td>
                                        <td>
                                            <Button variant="link" onClick={() => handleEdit(regla)} title='editar regla'><PencilFill className='text-warning' size={18} /></Button>{' '}
                                            <Button variant="link" onClick={() => handleDelete(regla.idDescuento)} title='eliminar regla'><TrashFill className='text-danger' size={18} /></Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No hay reglas de descuento disponibles</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default DescuentosTienda;
