import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Eye, EyeSlash, QuestionCircle, CameraFill, TrashFill } from 'react-bootstrap-icons';
import useAuth from '../../../../../hooks/useAuth';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Avatar } from '@mui/material';
import ImageCropperModal from '../ImageCropperModal';
import Select from 'react-select';
import Departamentos from '../../../../../utils/places-colombia';
import { API } from '../../../../../api/URL_API';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

function InformacionUsuario() {
    const { auth, setAuth } = useAuth();
    const firstLetter = auth.user?.name?.charAt(0) || '';
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Solo ingresa una contraseña si deseas cambiarla.
        </Tooltip>
    );

    const [profileData, setProfileData] = useState({
        idInq: '',
        Nit: '',
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        departamento: '',
        ciudad: '',
        createdAt: '',
        newPassword: '',
        avatar: auth.user?.avatar || ''
    });

    const [isEditing, setIsEditing] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [hoverAvatar, setHoverAvatar] = useState(false);
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [newAvatarImage, setNewAvatarImage] = useState(null);
    // Estados para departamentos y ciudades seleccionadas
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState(null);
    const [ciudadesOpciones, setCiudadesOpciones] = useState([]);

    useEffect(() => {
        if (auth.user) {
            setProfileData({
                idInq: auth.user.id || '',
                Nit: auth.user.Nit || '',
                nombre: auth.user.name || '',
                apellido: auth.user.ape || '',
                email: auth.user.email || '',
                telefono: auth.user.tel || '',
                departamento: auth.user.dep || '',
                ciudad: auth.user.ciudad || '',
                createdAt: auth.user.createdAt ? formatDate(auth.user.createdAt) : '',
                newPassword: '',
                avatar: auth.user.avatar || ''
            });

            // Inicializa ciudades si ya existe un departamento
            const departamento = Departamentos.find(dep => dep.departamento === auth.user.dep);
            if (departamento) {
                setCiudadesOpciones(departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })));
                setDepartamentoSeleccionado({ value: departamento.departamento, label: departamento.departamento });
            }
        }
    }, [auth.user]);

    const handleDepartamentoChange = (selectedOption) => {
        // Almacena solo el valor del departamento como string
        const departamentoValue = selectedOption?.value || '';

        // Encuentra el departamento y sus ciudades
        const departamento = Departamentos.find(dep => dep.departamento === departamentoValue);
        if (departamento) {
            const ciudades = departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad }));
            setCiudadesOpciones(ciudades);

            // Almacena la primera ciudad por defecto si hay ciudades disponibles
            setProfileData((prevData) => ({
                ...prevData,
                departamento: departamentoValue,
                ciudad: ciudades.length > 0 ? ciudades[0].value : ''
            }));
        }
    };

    const handleCiudadChange = (selectedOption) => {
        // Almacena solo el valor de la ciudad como string
        setProfileData((prevData) => ({
            ...prevData,
            ciudad: selectedOption?.value || ''
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setNewAvatarImage(file);
        setShowAvatarModal(true);
    };

    const handleImageSave = async (croppedImage) => {
        try {
           
            const formData = new FormData();
            formData.append('avatar', croppedImage);
            formData.append('idInq', profileData.idInq);

            const response = await axios.post(`${API}/api/auth/upload-avatar`, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const newImageUrl = response.data.avatarUrl;

            setProfileData((prevData) => ({
                ...prevData,
                avatar: `${newImageUrl}?t=${new Date().getTime()}`,
            }));

            setAuth((prevAuth) => ({
                ...prevAuth,
                user: {
                    ...prevAuth.user,
                    avatar: newImageUrl
                }
            }));

            localStorage.setItem('auth', JSON.stringify({
                ...auth,
                user: {
                    ...auth.user,
                    avatar: newImageUrl
                }
            }));

            setShowAvatarModal(false);

            Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'La imagen de perfil ha sido actualizada correctamente.',
                confirmButtonText: 'Aceptar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });
        } catch (err) {
            console.error('Error al actualizar la imagen:', err);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al actualizar la imagen de perfil.',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        } 
    };

    const handleDeleteAvatar = async () => {
        try {
            await axios.delete(`${API}/api/auth/delete-avatar`, {
                data: { idInq: profileData.idInq },
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            });

            setProfileData((prevData) => ({
                ...prevData,
                avatar: ''
            }));

            setAuth((prevAuth) => ({
                ...prevAuth,
                user: {
                    ...prevAuth.user,
                    avatar: ''
                }
            }));

            localStorage.setItem('auth', JSON.stringify({
                ...auth,
                user: {
                    ...auth.user,
                    avatar: ''
                }
            }));

            Swal.fire({
                icon: 'success',
                title: 'Eliminado',
                text: 'La imagen de perfil ha sido eliminada correctamente.',
                confirmButtonText: 'Aceptar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });
        } catch (err) {
            console.error('Error al eliminar la imagen:', err);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al eliminar la imagen de perfil.',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`${API}/api/auth/update`, {
                idInq: profileData.idInq,
                NitInq: profileData.Nit,
                nameInq: profileData.nombre,
                apeInq: profileData.apellido,
                emailInq: profileData.email,
                teleInq: profileData.telefono,
                depInq: profileData.departamento,
                ciudadInq: profileData.ciudad,
                newPassword: profileData.newPassword
            }, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            });

            // Éxito
            Swal.fire({
                title: 'Datos actualizados',
                text: 'Sus datos de usuario han sido actualizados correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });

            // Actualiza el usuario localmente
            const updatedUser = {
                ...auth.user,
                Nit: profileData.Nit,
                name: profileData.nombre,
                ape: profileData.apellido,
                email: profileData.email,
                tel: profileData.telefono,
                dep: profileData.departamento,
                ciudad: profileData.ciudad,
                updatedAt: new Date().toISOString()
            };

            setAuth((prevAuth) => ({
                ...prevAuth,
                user: updatedUser
            }));

            localStorage.setItem('auth', JSON.stringify({
                ...auth,
                user: updatedUser
            }));

        } catch (error) {
            // Imprimir el error completo para depurar
            console.error('Error al actualizar los datos: ', error);

            // Verificar si es un error de autenticación (401)
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: 'Error de autenticación',
                    text: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#da292e',
                    confirmButtonColor: "#da292e"
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Hubo un problema al actualizar los datos.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#da292e',
                    confirmButtonColor: "#da292e"
                });
            }
        }
        setIsEditing(false);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <div>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <p className='mb-2 mb-md-0 text-center'>
                    Información de usuario
                </p>

            </div>
            <Card className='mt-3 shadow-none'>
                <Card.Body>
                    <Row>
                        <Col md={4} className='d-flex align-items-center justify-content-center position-relative'>
                            <div
                                className='position-relative'
                                onMouseEnter={() => setHoverAvatar(true)}
                                onMouseLeave={() => setHoverAvatar(false)}
                            >
                                {profileData.avatar ? (
                                    <img
                                        src={`${profileData.avatar}`}

                                        alt="Avatar"
                                        className={`rounded-circle m-2 ${hoverAvatar ? 'img-darken' : ''}`}
                                        width="200"
                                    />
                                ) : (
                                    <Avatar
                                        alt='photoUser'
                                        style={{ width: 200, height: 200 }}
                                    >
                                        {firstLetter}
                                    </Avatar>
                                )}
                                {hoverAvatar && (
                                    <>
                                        <div className="d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
                                            <Button
                                                variant='link'
                                                className='edit-button me-2'
                                                onClick={() => document.getElementById('avatar-image-input').click()}
                                                title='Cambiar avatar'
                                            >
                                                <CameraFill size={24} />
                                            </Button>
                                            <Button
                                                variant='link'
                                                className='edit-button ms-2'
                                                onClick={handleDeleteAvatar}
                                                title='Eliminar avatar'
                                            >
                                                <TrashFill size={24} />
                                            </Button>
                                        </div>
                                        <input
                                            type='file'
                                            id='avatar-image-input'
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                        />
                                    </>
                                )}
                            </div>
                        </Col>
                        <Col md={8}>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>NIT</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='Nit'
                                                value={profileData.Nit}
                                                onChange={handleChange}
                                                readOnly={!isEditing} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='nombre'
                                                value={profileData.nombre}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Apellido</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='apellido'
                                                value={profileData.apellido}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type='email'
                                                name='email'
                                                value={profileData.email}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='telefono'
                                                value={profileData.telefono}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Departamento</Form.Label>
                                            <Select
                                                name="departamento"
                                                value={departamentoSeleccionado}
                                                onChange={handleDepartamentoChange}
                                                options={Departamentos.map(dep => ({
                                                    value: dep.departamento,
                                                    label: dep.departamento
                                                }))}
                                                isDisabled={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Ciudad</Form.Label>
                                            <Select
                                                name="ciudad"
                                                value={ciudadesOpciones.find(c => c.value === profileData.ciudad) || null}
                                                onChange={handleCiudadChange}
                                                options={ciudadesOpciones}
                                                isDisabled={!isEditing || !ciudadesOpciones.length}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Contraseña
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip}
                                                >
                                                    <QuestionCircle className='ms-2' />
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type={showPassword ? 'text' : 'password'}
                                                    name='newPassword'
                                                    placeholder='Nueva contraseña'
                                                    value={profileData.newPassword}
                                                    onChange={handleChange}
                                                    readOnly={!isEditing}
                                                />
                                                <InputGroup.Text>
                                                    <Button
                                                        variant="link"
                                                        onClick={handleClickShowPassword}
                                                        className="p-0"
                                                    >
                                                        {showPassword ? <EyeSlash /> : <Eye />}
                                                    </Button>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className='d-flex mt-2 mt-md-0 justify-content-end'>
                                    {!isEditing && (
                                        <Button variant='primary' onClick={handleEdit}>
                                            Editar
                                        </Button>
                                    )}
                                    {isEditing && (
                                        <div className='d-flex justify-content-end'>
                                            <Button
                                                variant='secondary'
                                                onClick={handleCancel}
                                                className='me-2'
                                            >
                                                Cancelar
                                            </Button>
                                            <Button type='submit' variant='primary'>Guardar</Button>
                                        </div>
                                    )}
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <ImageCropperModal
                show={showAvatarModal}
                onHide={() => setShowAvatarModal(false)}
                onSave={handleImageSave}
                onClose={() => setShowAvatarModal(false)}
                aspectRatio={1}
                imageFile={newAvatarImage}
            />
        </div>
    );
}

export default InformacionUsuario;
