import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import Departamentos from '../../../../../utils/places-colombia';
import { API } from '../../../../../api/URL_API';
import axios from 'axios';
import EditUserModal from './EditUserModal';
import AlertsUsuarios from './AlertsUsuarios';

// Componente principal
function Users({ loggedUser }) {  // Recibe el usuario logueado como prop
    const [activeModal, setActiveModal] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        username: '', email: '', role: '', userNit: '', lastName: '',
        userPhone: '', userAdress: '', userPassword: '', userDep: '', userCity: ''
    });
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [ciudadesOptions, setCiudadesOptions] = useState([]);

    const departamentosOptions = Departamentos.map(departamento => ({
        value: departamento.departamento,
        label: departamento.departamento
    }));

    const sampleRoles = [
        { name: 'Administrador' },
        { name: 'Estandar' }
    ];

    const handleCloseModal = () => {
        setActiveModal(null);
        setFormData({
            username: '', email: '', role: '', userNit: '', lastName: '',
            userPhone: '', userAdress: '', userPassword: '', userDep: '', userCity: ''
        });
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const { data } = await axios.get(`${API}/api/inquilinos`);
                setUsers(data);
                console.log("respuesta: ", data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);    

    const handleShowModal = (modalType) => {
        setActiveModal(modalType);
    };

    const handleAddUser = async () => {
        const newUser = {
            NitInq: formData.userNit,
            nameInq: formData.username,
            apeInq: formData.lastName,
            emailInq: formData.email,
            passInq: formData.userPassword,
            teleInq: formData.userPhone,
            dirInq: formData.userAdress,
            depInq: formData.userDep,
            ciudadInq: formData.userCity,
            role: formData.role,
        };

        if (!formData.userPassword) {
            return;
        }

        try {
            await axios.post(`${API}/api/inquilino`, newUser);

            setUsers([...users, newUser]);
            AlertsUsuarios.showSuccessMessage('Usuario creado exitosamente');
            handleCloseModal();
        } catch (error) {
            AlertsUsuarios.showErrorMessage("Error al guardar el usuario:", error.response.data.message);
        }
    };

    const confirmDeleteUser = (userId) => {
        AlertsUsuarios.showDeleteConfirmation(async () => {
            await handleDeleteUser(userId); // Llama a la función para manejar la eliminación
        });
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`${API}/api/inquilino/${userId}`);

            setUsers((prevUsers) => prevUsers.filter((user) => user.idInq !== userId));

            // Muestra alerta de eliminación exitosa
            AlertsUsuarios.showSuccessMessage('Usuario eliminado correctamente');
        } catch (error) {
            AlertsUsuarios.showErrorMessageDelete('Error al eliminar el usuario');
        } finally {
            handleCloseModal();
        }
    };

    const handleEditUser = async (updatedUser) => {
        const userData = {
            NitInq: updatedUser.userNit,
            nameInq: updatedUser.username,
            apeInq: updatedUser.lastName,
            emailInq: updatedUser.email,
            passInq: updatedUser.userPassword || undefined,
            teleInq: updatedUser.userPhone,
            dirInq: updatedUser.userAdress,
            depInq: updatedUser.userDep,
            ciudadInq: updatedUser.userCity,
            role: updatedUser.role,
            idInq: updatedUser.idInq,
        };

        try {
            await axios.put(`${API}/api/inquilino/${userData.idInq}`, userData);
            setUsers(users.map(user => user.idInq === userData.idInq ? { ...user, ...userData } : user));
            AlertsUsuarios.showUpdateSuccessMessage('Usuario actualizado correctamente');
            handleCloseModal();
        } catch (error) {
            AlertsUsuarios.showUpdateErrorMessage("Error al actualizar el usuario:", error);
        }
    };

    const handleDepartamentoChange = (selectedOption) => {
        setSelectedDepartamento(selectedOption);
        setFormData({ ...formData, userDep: selectedOption.value });
        const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
        const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
        setCiudadesOptions(ciudades);
        setSelectedCiudad(null);
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
        setFormData({ ...formData, userCity: selectedOption.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRoleChange = (selectedOption) => {
        setFormData({
            ...formData,
            role: selectedOption ? selectedOption.value : '',
        });
    };

    const roleOptions = sampleRoles.map(role => ({
        value: role.name,
        label: role.name,
    }));


    return (
        <div>
            <h2>Usuarios</h2>
            {loggedUser?.user?.role === "Administrador" && users.length < 5 && (  // Condición para mostrar el botón
                <Button variant="primary" onClick={() => handleShowModal('add')} className="mb-3">
                    Nuevo Usuario
                </Button>
            )}

            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>Nombre de Usuario</th>
                        <th>Email</th>
                        <th>Rol</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.idInq}>
                            <td>{user.nameInq}</td>
                            <td>{user.emailInq}</td>
                            <td>{user.role}</td>

                            <td>
                                {loggedUser?.user?.role === "Administrador" && (
                                    <>
                                        <Button variant="link" className="text-warning" onClick={() => { setSelectedUser(user); handleShowModal('edit'); }}>
                                            <PencilFill size={18} />
                                        </Button>{' '}
                                        <Button variant="link" className='text-danger' onClick={() => confirmDeleteUser(user.idInq)}>
                                            <TrashFill size={18} />
                                        </Button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {activeModal === 'edit' && selectedUser && (
                <EditUserModal
                    show={true}
                    handleClose={handleCloseModal}
                    user={selectedUser}
                    onSave={handleEditUser}
                />
            )}

            {activeModal === 'add' && (
                <Modal size='lg' show={activeModal === 'add'} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nuevo Usuario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group controlId='userNit' className='m-3 mt-0'>
                                        <Form.Label>No. Identificación</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='userNit'
                                            value={formData.userNit}
                                            onChange={handleChange}
                                            required
                                            autoComplete="off"
                                            placeholder='Ingrese el NIT del usuario'
                                        />
                                    </Form.Group>
                                    <Form.Group className="m-3">
                                        <Form.Label>Nombre de Usuario</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                            placeholder="Ingrese el nombres"
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='lastName' className='m-3'>
                                        <Form.Label>Apellidos</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='lastName'
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                            autoComplete="off"
                                            placeholder='Ingrese los apellidos'
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='userPhone' className='m-3'>
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control
                                            type='number'
                                            name='userPhone'
                                            value={formData.userPhone}
                                            onChange={handleChange}
                                            required
                                            autoComplete="off"
                                            placeholder='Ingrese el numero de contacto'
                                        />
                                    </Form.Group>
                                    <Form.Group className="m-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Ingrese el email"
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId='userDep' className='m-3 mt-0'>
                                        <Form.Label>Departamento</Form.Label>
                                        <Select
                                            value={selectedDepartamento}
                                            onChange={handleDepartamentoChange}
                                            options={departamentosOptions}
                                            placeholder='Selecciones Departamento'
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='userCity' className='m-3'>
                                        <Form.Label>Ciudad</Form.Label>
                                        <Select
                                            value={selectedCiudad}
                                            onChange={handleCiudadChange}
                                            options={ciudadesOptions}
                                            placeholder='Seleccione ciudad'
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='userAdress' className='m-3'>
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='userAdress'
                                            value={formData.userAdress}
                                            onChange={handleChange}
                                            required
                                            autoComplete="off"
                                            placeholder='Ingrese direccion'
                                        />
                                    </Form.Group>
                                    <Form.Group className="m-3">
                                        <Form.Label>Rol</Form.Label>
                                        <Select
                                            name="role"
                                            value={roleOptions.find(option => option.value === formData.role)}
                                            onChange={handleRoleChange}
                                            options={roleOptions}
                                            placeholder="Seleccione un rol"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='userPassword' className='m-3'>
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control
                                            type='password'
                                            name='userPassword'
                                            value={formData.userPassword}
                                            onChange={handleChange}
                                            required
                                            autoComplete="new-password"
                                            placeholder='Ingrese Contrasena'
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={handleAddUser}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}

            {activeModal === 'confirm' && (
                <Modal show={activeModal === 'confirm'} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Estás seguro de que deseas eliminar este usuario?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={handleDeleteUser}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default Users;
