import React, { useState, useEffect } from 'react';
import { Table, Form, Pagination, Button } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import APIserver from '../../../../api/axiosConfig';
import Swal from 'sweetalert2';

function TablaMarketing() {
    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    const fetchData = async (page, rowsPerPage) => {
        try {
            const response = await APIserver.get(`/api/marketingMostrar/datos`, {
                params: { page, rowsPerPage }
            });
            setData(response.data.data);
            setTotalRows(response.data.total);
        } catch (error) {
            console.error('Error fetching marketing data:', error);
        }
    };

    useEffect(() => {
        fetchData(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    const handleDelete = (id) => {
        // Llamamos a la confirmación de SweetAlert2
        Swal.fire({
            icon: 'warning',
            title: '¿Estás seguro?',
            text: `¿Quieres eliminar este suscriptor?`,
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Si se confirma la eliminación, procedemos
                    await APIserver.delete(`/api/marketingMostrar/eliminar/${id}`);
                    // Refrescar la tabla después de eliminar
                    fetchData(currentPage, rowsPerPage);
                    // Notificar que se ha eliminado correctamente
                    Swal.fire('Eliminado', 'El suscriptor ha sido eliminado correctamente', 'success');
                } catch (error) {
                    console.error('Error deleting subscriber:', error);
                    // Si hay error, mostrar mensaje de error
                    Swal.fire('Error', 'No se pudo eliminar el suscriptor', 'error');
                }
            }
        });
    };

    const totalPages = Math.ceil(totalRows / rowsPerPage);

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan='4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap text-black'>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Nombre</th>
                        <th>Correo Electrónico</th>
                        <th>Fecha de Suscripcion</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.idMark}>
                            <td>{item.nameuser}</td>
                            <td>{item.email}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => handleDelete(item.idMark)}
                                >
                                    <TrashFill size={18} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan='4'>
                            <Pagination className='d-flex justify-content-center'>
                                {[...Array(totalPages).keys()].map(page => (
                                    <Pagination.Item
                                        key={page + 1}
                                        active={page + 1 === currentPage}
                                        onClick={() => setCurrentPage(page + 1)}
                                    >
                                        {page + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
}

export default TablaMarketing;
