// Footer.js
import React from 'react';
import { Nav, Row, Col } from 'react-bootstrap';

const getCurrentYear = () => {
  return new Date().getFullYear();
};

function Footer() {
  return (
    <footer className="mx-4 mb-2 text-muted">
      <Row className="align-items-center">
        <Col xs={12} md={6} className="text-center text-md-start mb-2 mb-md-0">
          <div className="mb-0">
            Desarrollado por 
            <small className='text-decoration-none text-muted fw-bold ms-1'>
              <a
                href="https://www.tubisnez.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bold text-primary"
              >
                 TuBisnez 
              </a>
               &copy; 2023 - {getCurrentYear()}{' '}
            </small>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <Nav className="justify-content-center justify-content-md-end">
            <Nav.Item>
              <Nav.Link>Soporte</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>Licencia</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>Terminos y Condiciones</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
