import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Table, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { API } from '../../../../../api/URL_API';

function EnviosTienda() {
    const [reglasEnvio, setReglasEnvio] = useState([]);
    const [nuevaRegla, setNuevaRegla] = useState({
        tipoRegla: 'cantidad',
        min_cantidad: '',
        max_cantidad: '',
        min_total: '',
        max_total: '',
        costoEnvio: ''
    });
    const [editMode, setEditMode] = useState(false);
    const [idEdit, setIdEdit] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [tipoConfig, setTipoConfig] = useState(); // Valor por defecto

    useEffect(() => {
        fetchReglasEnvio(tipoConfig);  // Llamar a la API para obtener las reglas filtradas por tipoConfig
        fetchTipoConfig();
    }, [tipoConfig]);

    useEffect(() => {
        if (editMode) {
            setNuevaRegla(prevState => ({
                ...prevState,
                tipoRegla: tipoConfig // Asegura que tipoRegla esté sincronizado con tipoConfig
            }));
        }
    }, [tipoConfig, editMode]);

    const fetchReglasEnvio = async (tipoRegla = null) => {
        try {
            let url = `${API}/api/envios`;
            if (tipoRegla) {
                url += `?tipoRegla=${tipoRegla}`; // Agregar el filtro tipoRegla como query parameter
            }
            const response = await axios.get(url);
            setReglasEnvio(response.data);
        } catch (error) {
            console.error("Error fetching reglas de envío", error);
        }
    };

    const fetchTipoConfig = async () => {
        try {
            const response = await axios.get(`${API}/api/envios/configEnvio/2`);
            setTipoConfig(response.data.tipoConfig);  // Actualizar el estado del tipo de configuración
        } catch (error) {
            console.error("Error fetching tipoConfig", error);
        }
    };

    const handleSelectChanges = async (selectedOption) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Deseas actualizar la configuración de envío?",
            icon: 'warning',
            iconColor: "#f4bd61",
            showCancelButton: true,
            confirmButtonText: 'Sí, actualizar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setTipoConfig(selectedOption.value);

                // Actualizar las reglas de envío basadas en el filtro seleccionado
                fetchReglasEnvio(selectedOption.value);

                try {
                    await axios.put(`${API}/api/envios/configEnvio/2`, {
                        tipoConfig: selectedOption.value
                    });

                    Swal.fire({
                        title: 'Actualizado',
                        text: 'La configuración de envío ha sido actualizada correctamente.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#2fb380',
                        confirmButtonColor: "#2c4cc4"
                    });
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al actualizar la configuración de envío.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e"
                    });
                    console.error("Error updating tipoConfig", error);
                }
            }
        });
    };

    const handleChange = (e) => {
        setNuevaRegla({ ...nuevaRegla, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption) => {
        setNuevaRegla({
            ...nuevaRegla,
            tipoRegla: selectedOption.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editMode) {
                await axios.put(`${API}/api/envios/${idEdit}`, nuevaRegla);
                Swal.fire({
                    title: 'Actualizado',
                    text: 'La regla de envío ha sido actualizada correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            } else {
                await axios.post(`${API}/api/envios`, nuevaRegla);
                Swal.fire({
                    title: 'Creado',
                    text: 'La nueva regla de envío ha sido creada correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            }
            fetchReglasEnvio(tipoConfig);
            setShowModal(false);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al guardar la regla de envío.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
            console.error("Error saving regla de envío", error);
        }
    };

    const resetForm = () => {
        setNuevaRegla({
            tipoRegla: tipoConfig || 'cantidad',
            min_cantidad: '',
            max_cantidad: '',
            min_total: '',
            max_total: '',
            costoEnvio: ''
        });
        setEditMode(false);
        setIdEdit(null);
    };

    const openModal = () => {
        setNuevaRegla({
            tipoRegla: tipoConfig || 'cantidad',
            min_cantidad: '',
            max_cantidad: '',
            min_total: '',
            max_total: '',
            costoEnvio: ''
        });
        setEditMode(false);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        resetForm();
    };

    const handleEdit = (regla) => {
        setEditMode(true);
        setIdEdit(regla.idEnvio);
        setNuevaRegla({
            tipoRegla: regla.tipoRegla,
            min_cantidad: regla.min_cantidad,
            max_cantidad: regla.max_cantidad,
            min_total: regla.min_total,
            max_total: regla.max_total,
            costoEnvio: regla.costoEnvio
        });
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${API}/api/envios/${id}`);
                    Swal.fire({
                        title: 'Eliminado',
                        text: 'La regla de envío ha sido eliminada correctamente.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#2fb380',
                        confirmButtonColor: "#2c4cc4"
                    });
                    fetchReglasEnvio(tipoConfig);
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al eliminar la regla de envío.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e"
                    });
                    console.error("Error deleting regla de envío", error);
                }
            }
        });
    };

    const tipoReglaOptions = [
        { value: 'cantidad', label: 'Por Cantidad' },
        { value: 'total', label: 'Por Total' },
        { value: 'desable', label: 'Desactivado' }

    ];

    return (
        <Container>
            <div className='mt-4 d-flex flex-column flex-md-row justify-content-between'>
                <div>
                    <h2 className='fw-bold text-black'>Configuración de costo de envío</h2>
                    <p>Establece rangos para manejar el costo de envío de los pedidos que tus clientes realizan en la tienda,</p>
                </div>
                <div className='mt-2 mt-md-4'>
                    <Button variant="primary" onClick={openModal}>
                        {editMode ? 'Editar Regla de Envío' : 'Crear Regla de Envío'}
                    </Button>
                </div>
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editMode ? 'Editar' : 'Crear'} Regla de Envío</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='m-3 mt-0'>
                            <Form.Label>Tipo de Regla</Form.Label>
                            <Select
                                value={tipoReglaOptions.find(option => option.value === nuevaRegla.tipoRegla)}
                                onChange={handleSelectChange}
                                options={tipoReglaOptions}
                                isDisabled
                                required
                            />
                        </Form.Group>

                        {nuevaRegla.tipoRegla === 'cantidad' && (
                            <>
                                <Form.Group className='m-3'>
                                    <Form.Label>Cantidad mínima</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="min_cantidad"
                                        value={nuevaRegla.min_cantidad}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className='m-3'>
                                    <Form.Label>Cantidad máxima</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="max_cantidad"
                                        value={nuevaRegla.max_cantidad}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </>
                        )}

                        {nuevaRegla.tipoRegla === 'total' && (
                            <>
                                <Form.Group className='m-3'>
                                    <Form.Label>Total mínimo del pedido</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        name="min_total"
                                        value={nuevaRegla.min_total}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className='m-3'>
                                    <Form.Label>Total máximo del pedido</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        name="max_total"
                                        value={nuevaRegla.max_total}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </>
                        )}

                        <Form.Group className='m-3'>
                            <Form.Label>Costo de envío</Form.Label>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="costoEnvio"
                                value={nuevaRegla.costoEnvio}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                {editMode ? 'Actualizar' : 'Crear'} Regla
                            </Button>
                            <Button variant="secondary" onClick={closeModal} className="ms-2">
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            <Row>
                <Col>
                    <div className='m-2 d-flex flex-column flex-md-row justify-content-between'>
                        <h4 className='fw-bold'>Reglas de Envío Existentes</h4>
                        <Select
                            value={tipoReglaOptions.find(option => option.value === tipoConfig)}
                            onChange={handleSelectChanges}
                            options={tipoReglaOptions}
                            required
                        />
                    </div>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Cantidad/Total Mínimo</th>
                                <th>Cantidad/Total Máximo</th>
                                <th>Costo de Envío</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(reglasEnvio) && reglasEnvio.length > 0 ? (
                                reglasEnvio.map((regla) => (
                                    <tr key={regla.idEnvio}>
                                        <td>{regla.tipoRegla === 'cantidad' ? 'Por Cantidad' : 'Por Total'}</td>
                                        <td>{regla.tipoRegla === 'cantidad' ? regla.min_cantidad : regla.min_total}</td>
                                        <td>{regla.tipoRegla === 'cantidad' ? (regla.max_cantidad || 'Sin límite') : (regla.max_total || 'Sin límite')}</td>
                                        <td>{regla.costoEnvio}</td>
                                        <td>
                                            <Button variant="link" onClick={() => handleEdit(regla)}><PencilFill size={18} className='text-warning' /></Button>{' '}
                                            <Button variant="link" onClick={() => handleDelete(regla.idEnvio)}><TrashFill size={18} className='text-danger' /></Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No hay reglas de envío disponibles</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default EnviosTienda;
