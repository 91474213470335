import React, { useState } from 'react';
import { Offcanvas, ListGroup, Button, Row, Col, Image, InputGroup, Form } from 'react-bootstrap';
import { Trash3Fill, Whatsapp } from 'react-bootstrap-icons';
import { useCarrito } from './CarritoContext';
import { useParams } from 'react-router-dom';
import { API } from '../../../../api/URL_API';
import axios from 'axios';

function CarUni({ show, handleClose }) {
    const {
        carrito,
        removeFromCarrito,
        increaseQuantity,
        decreaseQuantity,
        clearCarrito,
        calculateShippingCost,
        configEnvio,
        calculateDiscount,
        calculateTotalWithDiscount,
        configDescuento = {}
    } = useCarrito();

    const { idSucursal } = useParams();
    const [nombreCliente, setNombreCliente] = useState('');
    const [numeroCliente, setNumeroCliente] = useState('');
    const [errors, setErrors] = useState({}); // Estado para manejar errores


    const isEnvioDisabled = configEnvio?.tipoConfig === 'desable';
    let envioCosto = calculateShippingCost();
    if (isEnvioDisabled) {
        envioCosto = 0;
    }

    // Total price calculation
    const totalPrecio = carrito.reduce((total, product) => {
        const price = configDescuento?.tipoConfig === 'desable'
            ? parseFloat(product.precioU.toString().replace('$', '').replace(',', ''))
            : parseFloat(calculateDiscount() > 0 ? product.precioU.toString().replace('$', '').replace(',', '') : product.precioM.toString().replace('$', '').replace(',', ''));

        const quantity = Number(product.quantity) || 1;
        return total + (price * quantity);
    }, 0);

    // Discount percentage and total with discount
    const porcentajeDescuento = calculateDiscount();
    const totalConDescuento = calculateTotalWithDiscount();

    const handleRemoveProduct = (idProducto, selectedColor, selectedSize) => {
        removeFromCarrito(idProducto, selectedColor, selectedSize);
    };

    const handleIncreaseQuantity = (idProducto, selectedColor, selectedSize) => {
        increaseQuantity(idProducto, selectedColor, selectedSize);
    };

    const handleDecreaseQuantity = (idProducto, selectedColor, selectedSize) => {
        decreaseQuantity(idProducto, selectedColor, selectedSize);
    };

    const handleClearCarrito = () => {
        clearCarrito();
    };

    const formatPhoneNumber = (number) => {
        const cleanedNumber = number.replace(/\D/g, '');
        return `+57${cleanedNumber}`;
    };

    const handleSendPedido = async () => {
        if (!validateForm()) {
            return; // Detener si hay errores en el formulario
          }
        try {
            // Determinar el tipo de pedido basado en el tipo de precio usado
            const tipoPedido = configDescuento?.tipoConfig === 'desable' || calculateDiscount() > 0
                ? 'unidad'
                : 'distribucion';
            const descuento = calculateDiscount();

            const pedido = {
                idCliente: 36,
                idSucursal: idSucursal ? parseInt(idSucursal) : 1,
                tipo: tipoPedido, // Ajustar el tipo de pedido
                creacion: new Date().toISOString().split('T')[0],
                vence: new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0],
                costoEnvio: envioCosto,
                nomClienteP: nombreCliente,
                numClienteP: numeroCliente,
                totalProductos: totalConDescuento,
                totalPedido: totalConDescuento + envioCosto,
                pagado: 0,
                pendiente: totalConDescuento + envioCosto,
                estado: 'pendiente',
                descuento: descuento
            };

            const detallesPedido = carrito.map(product => {
                const precio = configDescuento?.tipoConfig === 'desable'
                    ? parseFloat(product.precioU.toString().replace('$', '').replace(',', ''))
                    : parseFloat(calculateDiscount() > 0 ? product.precioU.toString().replace('$', '').replace(',', '') : product.precioM.toString().replace('$', '').replace(',', ''));

                const subtotal = precio * (product.quantity || 1);

                console.log('Detalles del Pedido - Product ID:', product.idProducto);
                console.log('Precio usado:', precio);
                console.log('Subtotal:', subtotal);

                return {
                    idProducto: product.idProducto,
                    cantidad: product.quantity || 1,
                    subtotal: subtotal,
                    totalPedido: subtotal,
                    idColor: product.selectedColor?.id,
                    idTalla: product.selectedSize?.id,
                };
            });

            // Solicitud POST con axios
            const response = await axios.post(`${API}/api/pedidos/carrito`, {
                pedido,
                detallesPedido
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Limpiar carrito después de que la solicitud POST sea exitosa
            clearCarrito();

            const data = response.data;
            const numeroPedido = data.numeroPedido;

            // Solicitud GET para obtener el número de la sucursal con axios
            const sucursalResponse = await axios.get(`${API}/api/pedidos/sucursal/${idSucursal}`);

            const sucursalData = sucursalResponse.data;
            const numeroSucursal = sucursalData.numSucursal;

            if (!numeroSucursal) {
                throw new Error('Número de sucursal no disponible');
            }

            const formattedNumeroSucursal = formatPhoneNumber(numeroSucursal);

            const mensajeWhatsApp =
                `¡Hola! Gracias por tu compra en Crisha Caps.

            Acabas de realizar un pedido con el número: *${numeroPedido}*.

            Puedes ver los detalles de tu compra y el contenido de tu carrito haciendo clic en el siguiente enlace:
            Ver pedido: https://www.crishacaps.com/viewOrder/${numeroPedido}

            Estamos procesando tu pedido y un asesor te contactará pronto para más detalles.

            Si tienes alguna pregunta o necesitas ayuda, no dudes en escribirnos.

            Saludos del equipo de Crisha Caps.
        `;

            const urlWhatsApp = `https://wa.me/${formattedNumeroSucursal}?text=${encodeURIComponent(mensajeWhatsApp)}`;

            window.open(urlWhatsApp, '_blank');

        } catch (error) {
            console.error('Error al enviar el pedido:', error);
            alert(`Error al enviar el pedido: ${error.response?.data?.error || error.message}`);
        }
    };


    const formatCurrency = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    const validateForm = () => {
        const newErrors = {};
    
        if (!nombreCliente) newErrors.nombreCliente = 'Este campo es obligatorio.';
        if (!numeroCliente) newErrors.numeroCliente = 'Este campo es obligatoria.';    
        setErrors(newErrors);
    
        // Si no hay errores, retorna true
        return Object.keys(newErrors).length === 0;
      };
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text1-cr fw-bold fst-italic'>Este es tu pedido 🔥</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup variant="flush">
                    {carrito.map((product) => (
                        <ListGroup.Item key={product.idProducto} style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)' }}>
                            <Row>
                                <Col xs={3} md={4}>
                                    <Image src={product.imgProducto} rounded fluid />
                                </Col>
                                <Col xs={4} md={6}>
                                    <span className='fw-bold text1-cr'>{product.nomProducto}</span>

                                    {product.selectedColor && (
                                        <div className="d-flex align-items-center">
                                            <span className="text-muted">Color: </span>
                                            <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                    backgroundColor: product.selectedColor.hexCode,
                                                    marginLeft: '10px',
                                                    border: '1px solid #ddd',
                                                }}
                                            />
                                        </div>
                                    )}
                                    {product.selectedSize && (
                                        <div className="text-muted">Talla: {product.selectedSize.nombre}</div>
                                    )}

                                    <div className="text1-cr fw-bold">
                                        $ {formatCurrency(
                                            configDescuento?.tipoConfig === 'desable'
                                                ? parseFloat(product.precioU.toString().replace('$', '').replace(',', ''))
                                                : parseFloat(calculateDiscount() > 0 ? product.precioU.toString().replace('$', '').replace(',', '') : product.precioM.toString().replace('$', '').replace(',', ''))
                                        )}
                                    </div>
                                </Col>
                                <Col xs={4} md={2} className="d-flex justify-content-end align-items-center">
                                    <Button
                                        variant="danger"
                                        className='button-decrement'
                                        size="sm"
                                        onClick={() => handleDecreaseQuantity(product.idProducto, product.selectedColor, product.selectedSize)}
                                    >
                                        -
                                    </Button>
                                    <span className="mx-2">{product.quantity || 1}</span>
                                    <Button
                                        variant='success'
                                        className='button-increment'
                                        size="sm"
                                        onClick={() => handleIncreaseQuantity(product.idProducto, product.selectedColor, product.selectedSize)}
                                        disabled={product.quantity >= product.stock} // Deshabilitar si no hay más stock
                                    >
                                        +
                                    </Button>

                                </Col>
                                <div className="d-flex justify-content-center mt-2">
                                    <button
                                        className='button-car w-100 d-flex justify-content-center align-items-center'
                                        onClick={() => handleRemoveProduct(product.idProducto, product.selectedColor, product.selectedSize)}
                                    >
                                        <Trash3Fill />
                                    </button>
                                </div>

                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <div className="d-flex justify-content-between mt-3">
                    <strong className='fw-bold text1-cr'>Subtotal:</strong>
                    <strong className='fw-bold text1-cr'>${formatCurrency(totalPrecio)}</strong>
                </div>

                {/* Mostrar descuento o precio mayorista dependiendo de la configuración */}
                {porcentajeDescuento > 0 ? (
                    <div className="d-flex justify-content-between mt-3">
                        <strong className='fw-bold text1-cr'>Descuento aplicado:</strong>
                        <strong className='fw-bold text-light title-seccion'>-{porcentajeDescuento}%</strong>
                    </div>
                ) : (
                    configDescuento?.tipoConfig !== 'desable' && calculateDiscount() === 0 && (
                        <div className="d-flex justify-content-between mt-3">
                            <strong className='fw-bold text1-cr'>Precio aplicado:</strong>
                            <strong className='fw-bold text-light title-seccion'>Gran Mayorista</strong>
                        </div>
                    )
                )}


                {/* Mostrar total con descuento solo si hay descuento */}
                {porcentajeDescuento > 0 && (
                    <div className="d-flex justify-content-between mt-3">
                        <strong className='fw-bold text1-cr'>Total con descuento:</strong>
                        <strong className='fw-bold text1-cr'>${formatCurrency(totalConDescuento)}</strong>
                    </div>
                )}

                <div className="d-flex justify-content-between mt-3">
                    <strong className='fw-bold text1-cr'>Envío:</strong>
                    <strong style={{ color: envioCosto > 0 ? 'inherit' : 'green' }}>
                        {isEnvioDisabled
                            ? '+Costo de Envío'
                            : (envioCosto > 0 ? `$ ${formatCurrency(envioCosto)}` : '¡GRATIS!')}
                    </strong>
                </div>

                <div className="d-flex justify-content-between mt-3">
                    <strong className='fw-bold text1-cr'>Total a pagar:</strong>
                    <strong className='fw-bold text1-cr'>${formatCurrency(totalConDescuento + envioCosto)}</strong>
                </div>

                <div className="mt-3">
    <InputGroup className="mb-3">
        <Form.Control
            type="text"
            placeholder="Ingresa tu nombre"
            value={nombreCliente}
            isInvalid={!!errors.nombreCliente}
            onChange={(e) => {
                setNombreCliente(e.target.value);
                if (errors.nombreCliente) setErrors((prev) => ({ ...prev, nombreCliente: null }));
            }}
        />
        <Form.Control.Feedback type="invalid">{errors.nombreCliente}</Form.Control.Feedback>
    </InputGroup>

    <InputGroup>
        <Form.Control
            type="tel"
            placeholder="Ingresa tu teléfono"
            value={numeroCliente}
            isInvalid={!!errors.numeroCliente}
            onChange={(e) => {
                setNumeroCliente(e.target.value);
                if (errors.numeroCliente) setErrors((prev) => ({ ...prev, numeroCliente: null }));
            }}
        />
        <Form.Control.Feedback type="invalid">{errors.numeroCliente}</Form.Control.Feedback>
    </InputGroup>
</div>


                {carrito.length === 0 ? (
                    <button
                        className="mt-3 w-100 button-send d-flex justify-content-center align-items-center"
                        disabled
                    >
                        No hay productos en el carrito
                    </button>
                ) : (
                    <button
                        className="mt-3 w-100 button-send d-flex justify-content-center align-items-center"
                        onClick={handleSendPedido}
                    >
                        <Whatsapp /> Enviar pedido
                    </button>
                )}

                <button className='mt-2 w-100 button-car d-flex justify-content-center align-items-center' onClick={handleClearCarrito}>
                    <Trash3Fill /> Vaciar carrito
                </button>
                <div className='mt-3'>
                    <p className='small text-info-emphasis text-center'>
                        Al enviar el pedido te redirigirá a WhatsApp con uno de nuestros
                        asesores donde podrás terminar el proceso.
                    </p>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default CarUni;
