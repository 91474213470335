import React, { useState, useEffect } from 'react';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import ProductoPedido from './componentes/productosPedido';
import ClientePedido from './componentes/clientePedido';
import { Download, PrinterFill, PencilFill, XCircleFill } from 'react-bootstrap-icons';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AlertsFacturas from './FacturarPedidos/Utils/AlertsFacturasPedidos';
import APIserver from '../../../api/axiosConfig';
import { API } from '../../../api/URL_API';

function DetallesPedido() {
    const { idPedido } = useParams(); // Obtén el ID del pedido de la URL
    const [pedido, setPedido] = useState(null);
    const navigate = useNavigate(); // Hook para redirigir al usuario

    useEffect(() => {
        const fetchPedido = async () => {
            try {
                const response = await APIserver.get(`/api/verpedidos/detalles/${idPedido}`);
                setPedido(response.data);
            } catch (error) {
                console.error('Error fetching pedido:', error);
            }
        };

        fetchPedido();
    }, [idPedido]);

    // Función para anular el pedido
    const handleAnularPedido = async (idPedido, estadoPedido) => {
        if (estadoPedido === 'facturado') {
            AlertsFacturas.showFacAlertAnular("Este pedido ya está facturado y no se puede anular.");
            return;
        }
        if (estadoPedido === 'anulado') {
            AlertsFacturas.showFacAlertAnularya("Este pedido ya está anulado y no se puede anular nuevamente.");
            return;
        }
    
        const isConfirmed = await AlertsFacturas.showConfirmAnularAlert(
            "Esta acción anulará el pedido y no podrá deshacerse."
        );
    
        if (isConfirmed) {
            try {
                const response = await fetch(`${API}/api/facturarPedidos/anular/${idPedido}`, {
                    method: 'PUT', // Usar PUT para actualizar el estado
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                
                if (response.ok) {
                    AlertsFacturas.showAnularAlert('El pedido ha sido anulado.');
                    // Redirigir a la página principal de pedidos
                    navigate('/admin/Pedidos/');
                } else {
                    AlertsFacturas.showErrorMessage('Hubo un error al anular el pedido.');
                }
            } catch (error) {
                console.error('Error anulando el pedido:', error);
                AlertsFacturas.showErrorMessage('Hubo un error al anular el pedido.');
            }
        }
    };

    if (!pedido) {
        return <div>Cargando...</div>;
    }

    const isAnulado = pedido.estadoPedido === 'anulado'; // Usar estadoPedido

    return (
        <div className='mx-2 mt-4 mt-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <h4 className='mb-2 mb-md-0 text-center'>
                    <span className='fw-bold text-black'>Pedido No.</span> {pedido.numPedido}
                </h4>
                <div className='d-flex mt-2 mt-md-0'>
                    {!isAnulado ? (
                        <Link to={`/admin/Pedidos/FacturarPedidos/CrearFacturarPedidos/${pedido.idPedido}/`}>
                            <Button variant='success'>
                                Facturar
                            </Button>
                        </Link>
                    ) : (
                        <Button variant='success' disabled>
                            Facturar
                        </Button>
                    )}

                    <Link to='/admin/Pedidos/'>
                        <Button variant='primary' className='ms-2'>Volver</Button>
                    </Link>
                    <Dropdown>
                        <Dropdown.Toggle variant='secondary' className='ms-2'>
                            Más
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <ButtonGroup>
                                <Button variant='link' className='text-primary' title='Imprimir'>
                                    <PrinterFill />
                                </Button>
                                <Button variant='link' className='text-success' title='Descargar'>
                                    <Download />
                                </Button>
                                <Button 
                                    variant='link' 
                                    className='text-muted' 
                                    title='Anular pedido'
                                    onClick={() => handleAnularPedido(pedido.idPedido, pedido.estadoPedido)}
                                    disabled={isAnulado} // Deshabilitar si el estado es "anulado"
                                >
                                    <XCircleFill />
                                </Button>
                            </ButtonGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {/* Detalles Cliente */}
            <div className='mt-4'>
                <ClientePedido cliente={pedido}  nombreCliente={pedido.nombreCliente}
                numeroCliente={pedido.numeroCliente}/>
            </div>
            {/* Detalles Productos */}
            <div className='mt-2'>
                <ProductoPedido productos={pedido.productos} envio={pedido.costoEnvio} descuento={pedido.descuento}/>
            </div>
        </div>
    );
}

export default DetallesPedido;