import React, { useState } from 'react';
import { Button, Dropdown, Modal, Form } from 'react-bootstrap';
import CustomToggle from '../styles/customToggle';
import { Download, NutFill, EnvelopeFill } from 'react-bootstrap-icons';
import TablaMarketing from './componentes/tablaMarketing';
import APIserver from '../../../api/axiosConfig';
import Swal from 'sweetalert2';

function AdminMarketing() {
    const [showModal, setShowModal] = useState(false);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [images, setImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [imageLink, setImageLink] = useState(''); // Aquí se almacena el enlace común para todas las imágenes
    const [isPromotion, setIsPromotion] = useState(false); // Para saber si se está creando una promoción o no

    const handleExport = async () => {
        try {
            const response = await APIserver.get(`/api/marketingMostrar/export`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'marketing_data.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        console.log('Archivos seleccionados:', files);

        // Filtrar imágenes duplicadas
        const newImages = files.filter(file => 
            !images.some(existingFile => existingFile.name === file.name && existingFile.size === file.size)
        );

        console.log('Imágenes nuevas (no duplicadas):', newImages);

        // Actualizar el estado solo con las nuevas imágenes
        setImages(prevImages => {
            const updatedImages = [...prevImages, ...newImages];
            console.log('Estado de imágenes después de actualización:', updatedImages);
            return updatedImages;
        });

        // Generar previsualización de las imágenes
        const previews = newImages.map(file => URL.createObjectURL(file));
        setPreviewImages(prevPreviews => {
            const updatedPreviews = [...prevPreviews, ...previews];
            console.log('Previsualizaciones después de actualización:', updatedPreviews);
            return updatedPreviews;
        });
    };

    // Eliminar una imagen
    const handleDeleteImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
        setPreviewImages(prevPreviews => prevPreviews.filter((_, i) => i !== index));
    };

    // Reemplazar una imagen
    const handleEditImage = (index) => {
        document.getElementById(`image-input-${index}`).click();
    };

    // Función para manejar el cambio de imagen (reemplazo)
    const handleImageReplace = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const newImageUrl = URL.createObjectURL(file);
            const updatedImages = [...images];
            updatedImages[index] = file; // Reemplazar la imagen
            setImages(updatedImages);

            const updatedPreviews = [...previewImages];
            updatedPreviews[index] = newImageUrl; // Reemplazar la previsualización
            setPreviewImages(updatedPreviews);
        }
    };

    // Función para enviar el correo
    const handleSendEmail = async () => {
        const formData = new FormData();
        formData.append('subject', emailSubject || ''); // Asunto puede ser vacío
    
        // Verifica el valor de isPromotion
        console.log('Valor de isPromotion:', isPromotion);  // Verifica si el estado es correcto
    
        // Generar etiquetas <a> con <img> para todas las imágenes
        const imageTags = images
            .map((_, index) => {
                return `
                    <center>
                        <a href="${imageLink}" target="_blank">
                            <img src="cid:image${index}" alt="" style="display: block; margin-bottom: 10px;"/>
                        </a>
                    </center>
                `;
            })
            .join('');
        const fullEmailBody = `${emailBody || ''}<br/>${imageTags}`; // Cuerpo puede ser vacío
        formData.append('body', fullEmailBody);
    
        // Agregar las imágenes al FormData
        images.forEach((image, index) => {
            formData.append(`images`, image); // Agregar imagen con nombre 'images'
        });
    
        // Agregar el valor de la promoción
        formData.append('promotional', isPromotion ? 'true' : 'false'); // Asegurarse de que se envíe correctamente
    
        setIsSending(true);
        try {
            await APIserver.post(`/api/marketingMostrar/correoMasivo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            Swal.fire({
                icon: 'success',
                title: 'Correo enviado',
                text: 'El correo se ha enviado exitosamente.',
                iconColor: '#28a745',
                confirmButtonColor: '#2c4cc4',
            });
            setShowModal(false);
        } catch (error) {
            console.error('Error sending email:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error al enviar',
                text: 'Hubo un error al enviar el correo.',
                iconColor: '#da292e',
                confirmButtonColor: '#2c4cc4',
            });
        } finally {
            setIsSending(false);
        }
    };
    

    const handleCloseModal = () => {
        setImages([]);
        setPreviewImages([]);
        setEmailSubject('');
        setEmailBody('');
        setImageLink(''); // Resetear el enlace de la imagen
        setShowModal(false);
    };

    return (
        <div className='mx-2 mt-3'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <p className='mb-2 mb-md-0'>
                    Aquí encontrarás la lista de correos electrónicos suscritos desde tu tienda.
                </p>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary' onClick={() => setShowModal(true)}>
                        <EnvelopeFill className='me-2' />
                        Enviar Correo a Suscriptores
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
                            <NutFill />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleExport}>
                                <Download className='text-success mx-1' /> Exportar
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <div className='my-4'>
                <TablaMarketing />
            </div>

            {/* Modal para enviar correos */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enviar Correo a Suscriptores</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="isPromotionCheckbox">
                            <Form.Check
                                type="checkbox"
                                label="Es una promoción"
                                checked={isPromotion}
                                onChange={(e) => setIsPromotion(e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Asunto</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Escribe el asunto aquí'
                                value={emailSubject}
                                onChange={(e) => setEmailSubject(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Mensaje</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={4}
                                placeholder='Escribe tu mensaje aquí'
                                value={emailBody}
                                onChange={(e) => setEmailBody(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Imágenes adjuntas</Form.Label>
                            <Form.Control
                                type='file'
                                multiple
                                accept='image/*'
                                onChange={handleFileChange}
                            />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Enlace de la imagen</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Escribe el enlace para las imágenes'
                                value={imageLink}
                                onChange={(e) => setImageLink(e.target.value)}
                            />
                        </Form.Group>

                        {previewImages.length > 0 && (
                            <div className='mb-3'>
                                <p>Previsualización:</p>
                                <div className='d-flex flex-wrap'>
                                    {previewImages.map((src, index) => (
                                        <div key={index} className='position-relative'>
                                            <img
                                                src={src}
                                                alt={`preview-${index}`}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    margin: 5,
                                                    objectFit: 'cover',
                                                    border: '1px solid #ccc',
                                                }}
                                            />
                                            <Button
                                                variant='danger'
                                                size='sm'
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                }}
                                                onClick={() => handleDeleteImage(index)}
                                            >
                                                Eliminar
                                            </Button>
                                            <Button
                                                variant='primary'
                                                size='sm'
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                }}
                                                onClick={() => handleEditImage(index)}
                                            >
                                                Editar
                                            </Button>
                                            <input
                                                id={`image-input-${index}`}
                                                type='file'
                                                style={{ display: 'none' }}
                                                accept='image/*'
                                                onChange={(e) => handleImageReplace(e, index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button
                        variant='primary'
                        onClick={handleSendEmail}
                        disabled={isSending}
                    >
                        {isSending ? 'Enviando...' : 'Enviar'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminMarketing;
