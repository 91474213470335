import React, { useState, useCallback, useEffect } from 'react';
import { Card, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { PlusCircleFill, Search, TrashFill } from 'react-bootstrap-icons';
import axios from 'axios';
import AlertsFacturas from './Utils/AlertsFacturasPedidos';
import ProductSearchModal from './ProductSearchModal';
import APIserver from '../../../../api/axiosConfig';
// Función para formatear en pesos colombianos
const formatCOP = (value) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

const ProductoRow = React.memo(({ producto, onChange, onRemove, onSearch, onKeyDown, onBlurCantidad }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange(producto.id, name, value);
    };

    const handleSearchClick = () => {
        onSearch(producto.id);
    };

    return (
        <tr id={`row-${producto.id}`}>
            <td>
                <Form.Control
                    type="text"
                    name="referencia"
                    value={producto.referencia}
                    onChange={handleChange}
                    onKeyDown={(e) => onKeyDown(e, producto.id)}
                    placeholder="Referencia"
                />
            </td>
            <td>
                <InputGroup>
                    <Form.Control
                        type="text"
                        name="nomProducto"
                        value={producto.nomProducto}
                        onChange={handleChange}
                        placeholder="Nombre"
                        readOnly
                    />
                    <Button variant='outline-success' size='sm' title='Buscar producto' onClick={handleSearchClick}>
                        <Search />
                    </Button>
                </InputGroup>
            </td>
            <td>
                <Form.Control
                    type="number"
                    name="cantidad"
                    value={producto.cantidad}
                    onChange={handleChange}
                    onBlur={() => onBlurCantidad(producto.id, producto.cantidad)}  // Usa la función de cantidad en onBlur
                    placeholder="Cantidad"
                />
                {producto.stockErrorMessage && (
                    <div style={{ color: 'red', marginTop: '5px' }}>
                        {producto.stockErrorMessage}
                    </div>
                )}
            </td>
            <td>
                <Form.Control
                    type="text"
                    name="valorUnitario"
                    value={formatCOP(parseFloat(producto.valorUnitario) || 0)}
                    onChange={handleChange}
                    placeholder="Valor Unitario"
                    readOnly
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    value={formatCOP((parseFloat(producto.cantidad) * parseFloat(producto.valorUnitario) || 0))}
                    placeholder="Total"
                    disabled
                />
            </td>
            <td>
                <Button variant='danger' onClick={() => onRemove(producto.id)}>
                    <TrashFill />
                </Button>
            </td>
        </tr>
    );
});

const Productos = ({ setProductos, setSubtotal, setTotal, setCantItem, setEnvio, tipoFactura, productos, costoEnvio, descuento, setDescuento }) => {

    const [localProductos, setLocalProductos] = useState(productos || [{
        id: Date.now(),
        referencia: '',
        nomProducto: '',
        cantidad: 1,
        valorUnitario: 0,
        autoCompleted: false,
        stockErrorMessage: '',  // Agregamos el mensaje de error aquí
    }]);
    const [localEnvio, setLocalEnvio] = useState(costoEnvio); // Inicializa con costoEnvio o 0
    const [showModal, setShowModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentProductId, setCurrentProductId] = useState(null);
    const [isAddingRow, setIsAddingRow] = useState(false);
    const [localDescuento, setLocalDescuento] = useState(descuento);
    const [shouldSimulateEnter, setShouldSimulateEnter] = useState(true);  // Nuevo estado para controlar la simulación
    const [stockErrorMessage, setStockErrorMessage] = useState('');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // Se ejecuta después de 4 segundos
            setLocalProductos(productos || localProductos);
        }, 100); // 4000ms = 4 segundos
    
        // Limpiar el timeout si el componente se desmonta o si `productos` cambia antes de los 4 segundos
        return () => clearTimeout(timeoutId);
    }, [productos]); // Solo se ejecuta cuando `productos` cambia


    useEffect(() => {
        if (costoEnvio !== undefined) {
            setLocalEnvio(costoEnvio);
        }
    }, [costoEnvio]);

    useEffect(() => {
        if (!isNaN(localEnvio)) {
            setEnvio(parseFloat(localEnvio));
        }
    }, [localEnvio, setEnvio]);

    useEffect(() => {
        if (descuento !== undefined) {
            setLocalDescuento(descuento);
        }
    }, [costoEnvio]);

    useEffect(() => {
        if (!isNaN(localDescuento)) {
            setDescuento(parseFloat(localDescuento));
        }
    }, [localDescuento, setDescuento]);
    useEffect(() => {
        // Asegura que siempre que cambien los productos locales, se actualice el estado de productos en el padre
        setProductos(localProductos);
    }, [localProductos, setProductos]);


    const handleChangeCantidadDirecta = (id, cantidad) => {
        // Convertir cantidad a un número para evitar errores con strings
        const cantidadNum = parseFloat(cantidad) || 0;

        // Buscar el producto correspondiente y actualizar la cantidad
        const updatedProductos = localProductos.map(p =>
            p.id === id ? { ...p, cantidad: cantidadNum } : p
        );

        setLocalProductos(updatedProductos); // Actualiza el estado local
        setProductos(updatedProductos); // Propaga el cambio al componente padre
    };

    const handleAddRow = () => {
        setIsAddingRow(true);
        setLocalProductos(prevProductos => [
            ...prevProductos,
            {
                id: Date.now(),
                referencia: '',
                nomProducto: '',
                cantidad: 1,
                valorUnitario: 0,
                autoCompleted: false,
                stockErrorMessage: '',  // Inicializamos también aquí

            }
        ]);
        // Detenemos la simulación de Enter al agregar una línea
        setShouldSimulateEnter(false);
        setTimeout(() => setIsAddingRow(false), 100); // Ajustar el tiempo si es necesario
    };
     const handleRemoveRow = (id) => {
        setLocalProductos(prevProductos => {
            const updatedProductos = prevProductos.filter(p => p.id !== id);
            setProductos(updatedProductos); // Actualiza el estado en el componente padre
            return updatedProductos;
        });
    };

    const handleChange = useCallback((id, name, value) => {
        if (name === 'cantidad' || name === 'valorUnitario') {
            value = parseFloat(value) || 0;
        }

        const updatedProductos = localProductos.map(p =>
            p.id === id ? { ...p, [name]: value } : p
        );

        if (name === 'cantidad') {
            const producto = updatedProductos.find(p => p.id === id); // Obtenemos la versión actualizada

            setTimeout(async () => {
                if (producto && producto.idProducto) {
                    try {
                        const response = await APIserver.get(`/api/productos/${producto.idProducto}`);
                        const cantidadDisponible = response.data.stock;

                        // Comprobamos si la cantidad ingresada excede la cantidad disponible
                        if (parseInt(value) > cantidadDisponible) {
                            const errorMessage = `La cantidad pedida (${value}) excede el stock, se ajusto a (${cantidadDisponible}).`;

                            // Ajustamos la cantidad al stock disponible y actualizamos el mensaje de error
                            const adjustedProductos = updatedProductos.map(p =>
                                p.id === id ? { ...p, cantidad: cantidadDisponible, stockErrorMessage: errorMessage } : p
                            );

                            // Actualizamos el estado local con la cantidad ajustada
                            setLocalProductos(adjustedProductos);
                            setProductos(adjustedProductos);  // Se envía el valor ajustado
                        } else {
                            // Limpiamos el mensaje de error si es válido
                            const resetErrorProductos = updatedProductos.map(p =>
                                p.id === id ? { ...p, stockErrorMessage: '' } : p
                            );

                            // Actualizamos el estado con la cantidad correcta
                            setLocalProductos(resetErrorProductos);
                            setProductos(resetErrorProductos);
                        }
                    } catch (error) {
                        console.error('Error al verificar stock del producto:', error);
                    }
                }
            }, 0);
        } else {
            // Si el campo no es "cantidad", simplemente actualizamos
            setProductos(updatedProductos);  // Actualizamos el estado padre
        }

        // Actualizamos el estado local
        setLocalProductos(updatedProductos);
    }, [localProductos, setProductos]);
    const handleKeyDown = async (e, id) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const producto = localProductos.find(p => p.id === id);
            if (producto) {
                try {
                    // Obtener los datos del producto según la referencia
                    const response = await APIserver.get(`/api/referencia/${producto.referencia}`);
                    const product = response.data;
    
                    if (product) {
                        // Verificar si ya existe otro producto con la misma referencia
                        const existingProduct = localProductos.find(p => p.referencia === product.referencia && p.id !== id);
                        if (existingProduct) {
                            // Si ya existe un producto con la misma referencia, sumamos 1 a su cantidad
                            const nuevaCantidad = existingProduct.cantidad + 1;
                            const stockResponse = await APIserver.get(`/api/productos/${existingProduct.idProducto}`);
                            const stockDisponible = stockResponse.data.stock;
    
                            if (nuevaCantidad > stockDisponible) {
                                // Ajustamos la cantidad al stock disponible y mostramos mensaje de error
                                setLocalProductos(prevProductos =>
                                    prevProductos.map(p =>
                                        p.referencia === existingProduct.referencia
                                            ? {
                                                ...p,
                                                cantidad: stockDisponible,
                                                stockErrorMessage: `La cantidad pedida (${nuevaCantidad}) excede el stock, se ajustó a (${stockDisponible}).`
                                            }
                                            : p
                                    )
                                );
                            } else {
                                // Si el stock es suficiente, actualizamos la cantidad del producto existente
                                setLocalProductos(prevProductos =>
                                    prevProductos.map(p =>
                                        p.referencia === existingProduct.referencia
                                            ? { ...p, cantidad: nuevaCantidad, stockErrorMessage: '' }  // Limpiamos mensaje de error
                                            : p
                                    )
                                );
                            }
    
                            // Reiniciar el campo de referencia repetida (solo en el producto duplicado)
                            setLocalProductos(prevProductos =>
                                prevProductos.map(p =>
                                    p.id === id
                                        ? { ...p, referencia: '', nomProducto: '', cantidad: 0, stockErrorMessage: '' }
                                        : p
                                )
                            );
                        } else {
                            // Si no hay duplicado, se actualiza el producto con la referencia
                            const stockResponse = await APIserver.get(`/api/productos/${product.idProducto}`);
                            const stockDisponible = stockResponse.data.stock;
    
                            if (producto.cantidad > stockDisponible) {
                                // Ajustar la cantidad al stock disponible
                                setLocalProductos(prevProductos =>
                                    prevProductos.map(p =>
                                        p.id === id ? {
                                            ...p,
                                            referencia: product.referencia,
                                            nomProducto: product.nomProducto,
                                            valorUnitario: tipoFactura === 'distribucion' ? product.precioM : product.precioU,
                                            descuento: 0,
                                            idProducto: product.idProducto,
                                            cantidad: stockDisponible,  // Ajustar al stock disponible
                                            stockErrorMessage: `La cantidad pedida (${producto.cantidad}) excede el stock, se ajustó a (${stockDisponible}).`,
                                            autoCompleted: true
                                        } : p
                                    )
                                );
                            } else {
                                // No hay problemas con el stock, limpiar cualquier mensaje de error
                                setLocalProductos(prevProductos =>
                                    prevProductos.map(p =>
                                        p.id === id ? {
                                            ...p,
                                            referencia: product.referencia,
                                            nomProducto: product.nomProducto,
                                            valorUnitario: tipoFactura === 'distribucion' ? product.precioM : product.precioU,
                                            descuento: 0,
                                            idProducto: product.idProducto,
                                            cantidad: producto.cantidad,  // Mantener la cantidad original
                                            stockErrorMessage: '',  // Limpiamos mensaje de error
                                            autoCompleted: true
                                        } : p
                                    )
                                );
                            }
                        }
    
                        // Llamamos a la función para manejar la simulación del Enter
                        setTimeout(() => simulateEnterKeyForAutoCompleted(), 500);
                    }
                } catch (error) {
                    console.error('Error al buscar el producto por referencia:', error);
                }
            }
        }
    };


    const simulateEnterKeyForAutoCompleted = () => {
        if (isAddingRow) return; // No simula Enter mientras se está agregando una fila
        localProductos.forEach(producto => {
            if (!producto.autoCompleted) {
                const input = document.querySelector(`#row-${producto.id}`);
                if (input) {
                    input.focus();
                    const event = new KeyboardEvent('keydown', {
                        key: 'Enter',
                        keyCode: 13,
                        which: 13,
                        bubbles: true,
                        cancelable: true
                    });
                    input.dispatchEvent(event);
                } else {
                    console.error(`Input field for product ID ${producto.id} not found.`);
                }
            }
        });
    };

    const handleSearch = (id) => {
        setCurrentProductId(id);
        setShowModal(true);
    };

    const handleSearchQueryChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 0) {
            try {
                const response = await APIserver.get(`/api/info/productos`, {
                    params: { nombre: query, tipoFactura }
                });
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error al buscar productos:', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleSelectProduct = (product) => {
        setLocalProductos(prevProductos => {
            // Buscar si ya existe un producto con la misma referencia
            const existingProduct = prevProductos.find(p => p.referencia === product.referencia);

            if (existingProduct) {
                // Nueva cantidad sería la cantidad actual + 1
                const nuevaCantidad = existingProduct.cantidad + 1;

                // Verificar si la nueva cantidad supera el stock usando la función existente
                if (nuevaCantidad > existingProduct.stockDisponible) { // Aquí usamos el stock ya obtenido del producto
                    AlertsFacturas.showStockAlert(existingProduct.stockDisponible);  // Mostrar alerta si la cantidad supera el stock

                    // Limpiar el campo de referencia repetida
                    return prevProductos.map(p =>
                        p.id === currentProductId
                            ? { ...p, referencia: '', nomProducto: '', cantidad: 0 } // Reiniciar referencia y otros campos
                            : p
                    );
                }

                // Si la cantidad es válida, actualizamos la cantidad del producto existente
                const updatedProductos = prevProductos.map(p =>
                    p.referencia === product.referencia
                        ? { ...p, cantidad: nuevaCantidad }  // Sumar 1 a la cantidad del producto ya existente
                        : p
                );

                // Limpiar el campo de referencia de la fila en la que se hizo la selección
                return updatedProductos.map(p =>
                    p.id === currentProductId
                        ? { ...p, referencia: '', nomProducto: '', cantidad: 0 } // Reiniciar referencia y otros campos
                        : p
                );
            } else {
                // Si no existe el producto con la misma referencia, lo añadimos como nuevo
                return prevProductos.map(p =>
                    p.id === currentProductId
                        ? {
                            ...p,
                            referencia: product.referencia,
                            nomProducto: product.nomProducto,
                            valorUnitario: tipoFactura === 'distribucion' ? product.precioM : product.precioU,
                            descuento: 0, // Descuento en 0
                            idProducto: product.idProducto,
                            stockDisponible: product.stock // Asegurarnos de guardar el stock para este producto
                        }
                        : p
                );
            }
        });

        setShowModal(false);
    };

    const handleEnvioChange = (e) => {
        const envioValue = parseFloat(e.target.value);
        setLocalEnvio(envioValue);
    };

    const handleDescuentoChange = (e) => {
        const descuentoValue = parseFloat(e.target.value);
        setLocalDescuento(descuentoValue);
    };

    const calculateSubtotal = useCallback(() => {
        return localProductos.reduce((total, producto) => {
            const cantidad = parseFloat(producto.cantidad) || 0;
            const valorUnitario = parseFloat(producto.valorUnitario) || 0;
            const productoTotal = cantidad * valorUnitario;
            return total + productoTotal;
        }, 0).toFixed(2);
    }, [localProductos]);

    const calculateTotalItems = useCallback(() => {
        return localProductos.reduce((total, producto) => {
            const cantidad = parseInt(producto.cantidad, 10);
            return total + (isNaN(cantidad) ? 0 : cantidad);
        }, 0);
    }, [localProductos]);


    const calculateTotal = useCallback(() => {
        const subtotal = parseFloat(calculateSubtotal()) || 0;
        const descuentoTotal = subtotal * (parseFloat(localDescuento) || 0) / 100;
        const envio = parseFloat(localEnvio) || 0;
        return (subtotal + envio - descuentoTotal).toFixed(2);
    }, [calculateSubtotal, localEnvio, localDescuento]);

    const consolidateDuplicatedReferences = () => {
        setLocalProductos(prevProductos => {
            // Crear un mapa para agrupar productos por referencia.
            const referenceMap = {};
            let hasChanges = false;
    
            prevProductos.forEach(producto => {
                if (producto.referencia) {
                    if (!referenceMap[producto.referencia]) {
                        referenceMap[producto.referencia] = { ...producto }; // Clonar el producto inicial
                    } else {
                        // Si ya existe, sumar cantidades
                        const cantidadOriginal = referenceMap[producto.referencia].cantidad;
                        referenceMap[producto.referencia].cantidad += producto.cantidad;
    
                        // Validar si excede el stock
                        if (referenceMap[producto.referencia].cantidad > producto.stockDisponible) {
                            referenceMap[producto.referencia].cantidad = producto.stockDisponible;
                            AlertsFacturas.showStockAlert(producto.stockDisponible); // Mostrar alerta
                        }
    
                        // Detectar cambios
                        if (referenceMap[producto.referencia].cantidad !== cantidadOriginal) {
                            hasChanges = true;
                        }
                    }
                } else {
                    // Si no tiene referencia, agregar directamente
                    referenceMap[producto.id] = { ...producto };
                }
            });
    
            const updatedProductos = Object.values(referenceMap);
    
            // Evitar actualizaciones innecesarias
            if (JSON.stringify(updatedProductos) === JSON.stringify(prevProductos)) {
                return prevProductos;
            }
    
            return updatedProductos;
        });
    };
    
    const simulateEnterKeyOnReferenciaFields = useCallback(() => {
     
        if (isAddingRow) return;
    
        // Consolidar referencias duplicadas antes de ejecutar "Enter automático".
        consolidateDuplicatedReferences();
    
        // Simular la acción de Enter para cada campo relevante
        setTimeout(() => {
            localProductos.forEach(producto => {
                if (!producto.autoCompleted) {
                    const input = document.querySelector(`#row-${producto.id} input[name="referencia"]`);
                    if (input) {
                        input.focus();
                        const event = new KeyboardEvent('keydown', {
                            key: 'Enter',
                            keyCode: 13,
                            which: 13,
                            bubbles: true,
                            cancelable: true
                        });
                        input.dispatchEvent(event);
                    } else {
                        console.error(`Input field for product ID ${producto.id} not found.`);
                    }
                }
            });
        }, 100); // Aseguramos un pequeño delay para que se complete la consolidación del estado.
    }, [localProductos, isAddingRow]);
    
    useEffect(() => {
        // Ejecutar solo si hay productos pendientes de completar
        if (shouldSimulateEnter && localProductos.some(p => !p.autoCompleted)) {
            simulateEnterKeyOnReferenciaFields();
        }
    }, [shouldSimulateEnter, simulateEnterKeyOnReferenciaFields]); // Ajustar dependencias
    
    
    useEffect(() => {
        const subtotal = parseFloat(calculateSubtotal()) || 0;
        const totalItems = calculateTotalItems();
        const total = parseFloat(calculateTotal()) || 0;

        setSubtotal(subtotal);
        setTotal(total);
        setCantItem(totalItems);
    }, [localProductos, localEnvio, localDescuento, calculateSubtotal, calculateTotal, calculateTotalItems, setSubtotal, setTotal, setCantItem]);


    return (
        <Card className='mt-2 shadow-none'>
            <Card.Header className='fw-bold'>Productos</Card.Header>
            <Table responsive className='shadow-none text-center'>
                <thead className='bg-primary-subtle'>
                    <tr>
                        <th>Referencia</th>
                        <th>Nombre</th>
                        <th>Cant.</th>
                        <th>Valor Unitario</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {localProductos.map(producto => (
                        <ProductoRow
                            key={producto.id}
                            producto={producto}
                            onChange={handleChange}
                            onRemove={handleRemoveRow}
                            onSearch={handleSearch}
                            onKeyDown={handleKeyDown}
                            onBlurCantidad={handleChangeCantidadDirecta}
                            stockErrorMessage={stockErrorMessage} // Pasar el mensaje de error aquí

                        />

                    ))}

                    <tr>
                        <td colSpan="7" className='text-start'>
                            <Button variant='outline-success' onClick={handleAddRow}>
                                <PlusCircleFill size={18} className='me-2' /> Agregar Línea
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Subtotal:</td>
                        <td colSpan="2" className='text-start'>{formatCOP(calculateSubtotal())}</td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Descuento:</td>
                        <td colSpan="2" className='text-start'>
                            <InputGroup size='sm'>
                                <InputGroup.Text>%</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={descuento}
                                    onChange={handleDescuentoChange}
                                    placeholder="Descuento"
                                />
                            </InputGroup>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Items:</td>
                        <td colSpan="2" className='text-start'>{calculateTotalItems()}</td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Envio:</td>
                        <td colSpan="2" className='text-start'>
                            <InputGroup size='sm'>
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={localEnvio}
                                    onChange={handleEnvioChange}
                                    placeholder="Envio"
                                />
                            </InputGroup>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Total:</td>
                        <td colSpan="2" className='text-start'>{formatCOP(calculateTotal())}</td>
                    </tr>
                </tbody>

            </Table>

            <ProductSearchModal
                showModal={showModal}
                setShowModal={setShowModal}
                searchQuery={searchQuery}
                handleSearchQueryChange={handleSearchQueryChange}
                searchResults={searchResults}
                formatCOP={formatCOP}
                handleSelectProduct={handleSelectProduct}
            />
        </Card>
    );
};

export default Productos;
